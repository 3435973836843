const {bolt} = require('../dist/versions.json')
const {loadScript} = require('./utils')
const {startTb} = require('./index-tb')

if (window.location.search.indexOf('BoltSource') < 0) { //eslint-disable-line lodash/prefer-includes
    window.boltBase = `https://static.parastorage.com/services/wix-bolt/${bolt}`
}

const main = `${window.boltBase}/bolt-main/app/main-r.min.js`

loadScript(
    'https://static.parastorage.com/unpkg-semver/wix-recorder@^1/app.bundle.min.js'
).catch(e => console.log(e))

//eslint-disable-next-line lodash/prefer-includes
if (window.location.search.indexOf('viewerSource') >= 0) {
    startTb()
} else {
    loadScript(main)
        .then(() => {})
        .catch(e => console.log(e))
}


