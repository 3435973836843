const {loadScript, loadStyle} = require('./utils')

const startTb = () => {
    const viewerSource = new URLSearchParams(window.location.search).get('viewerSource')
    window.viewerBase = /^\d/.test(viewerSource) ? `https://static.parastorage.com/services/wix-thunderbolt/${viewerSource}` : viewerSource
    fetch(`${window.viewerBase}/manifest.min.json`)
        .then(x => x.json())
        .then(manifest => Object.entries(manifest).forEach(([entry, src]) => {
            if (/.js$/.test(src)) {
                loadScript(src, entry)
            } else if (/.css$/.test(src)) {
                loadStyle(src, entry)
            }
        }))
        .catch(e => console.log(e))
}

module.exports = {startTb}