const loadScript = (src, name) =>
    new Promise(resolve => {
        const element = document.createElement('script')
        element.async = false //make sure the scripts execute in the order they are added
        element.src = src
        element.onload = () => resolve()
        document.body.appendChild(element)
    }).catch(err => {
        console.log(`Failed to load script ${name}: ${err.message}`) //eslint-disable-line no-console
    })

const loadStyle = (href, name) =>
    new Promise(resolve => {
        const element = document.createElement('link')
        element.rel = 'stylesheet'
        element.href = href
        element.onload = () => resolve()
        document.body.appendChild(element)
    }).catch(err => {
        console.log(`Failed to load script ${name}: ${err.message}`) //eslint-disable-line no-console
    })

module.exports = {loadScript, loadStyle}